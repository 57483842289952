import React, { useState, useContext, useEffect } from "react";
import { LocationContext } from "../context";
import Notfound from "./Notfound";

const authorizedIP = "98.167.72.97";

export const SessionData = () => {
  const { IPv4 } = useContext(LocationContext);
  const downloadSessionData = () => {
    const sessionDataRaw = require("../data/sessionData.json");
    console.log("sessionDataRaw: ", sessionDataRaw);
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(sessionDataRaw, null, 2)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "data.json";
    link.click();
  };
  useEffect(() => {
    if (IPv4 === authorizedIP) {
    }
  }, [IPv4]);
  return (
    <Notfound>
      {IPv4 && IPv4 === authorizedIP && (
        <button onClick={() => downloadSessionData()}>Test</button>
      )}
      <br />
    </Notfound>
  );
};

export default SessionData;
