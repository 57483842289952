import React, { useState, useEffect } from "react";

export const freelanceTestimonials = [
  {
    id: 0, smallText: true,
    content: <>
    <p>
      Working with Matt on my Jon Dollars app has been an absolute source of pleasure and source of
      great excitement for me. He took my initially simple idea and
      has worked very hard to make it into a highly functional app with many interesting features. He
      has been extremely creative, taking initiative to improve the design and
      use.
    </p><br />
    <p>
      He has been very prompt with his responses and has met every deadline reliably. He has welcomed
      input and feedback to which he responded positively and effectively. He has been
      very accomodating and flexible in his work which made the process run smoothly at all times.
    </p><br />
    <p>I am a teacher and Matt has worked with me on an app for use with my classes. The students'
      response has been highly positive and I'm excited to put into use what will certainly prove
      a fantastic tool.
    </p><br />
    <p>I would gladly work with Matt again in the future and recommend his services highly.</p>
    </>,
    author: {
      name: 'Jonathan Payne',
      designation: <b>Elementary School Teacher</b>
    }
  },
  {
    id: 1, smallText: false,
    content: <>
      <p>
        Matt's been an amazing developer to work with. He's thorough in his work, clear and responsive in
        his communication, and you can tell that he genuinely cares about the software he creates.
      </p><br />
      <p>
        In his time spent working with us, he built a highly interactive and functional website for us to
        train our clients remotely and we have no doubt that it will be a hit with live users once we
        launch.
      </p><br />
      <p>
        We're excited to keep working with him in the future and I'd recommend him highly for any kind of
        software or development position.
      </p>
    </>,
    author: {
      name: 'Alex Bryce',
      designation: <span>Personal Trainer, Co-Founder of <b>Electrum Performance</b></span>
    }
  },
  {
    id: 2, smallText: false,
    content: <>
      <p>
        I had the great pleasure of working with Matthew and his team recently on a project designing an
        automatic trading algo to trade based upon inflection numbers.
      </p><br />
      <p>
        At all times Matt's communication was exemplary and his talent for explaining complicated things
        in an easy to understand, no BS way - made this entire process run so much more smoothly than it
        might have with others. He worked diligently and exceeded all expected time frame targets for
        completion.
      </p><br />
      <p>
        I trust him and his team completely and am looking forward to continuing to work with them on many
        future endeavours. I do not hesitate to recommend him in the strongest possible terms.
      </p>
    </>,
    author: {
      name: 'Michael Rodgers',
      designation: 'Daytrader'
    }
  }, 
  {
    id: 3, smallText: false,
    content: <>
      <p>Matt and his team worked diligently to understand my complex strategy and translate it 
        into code for a backtesting and live algorithm. They did an amazing job. The biggest benefit I see 
        to working with Matt is his trading background and experience. Most developers I have worked with have 
        never even seen a trading terminal. Happy with the work so far and will call Matt next time I have another 
        trading strategy to code up.
      </p>
    </>,
    author: {
      name: 'Dan Ushman',
      designation: '3-year discretionary trader ($100,000 in trading capital)'
    }
  },{
    id: 4, smallText: false,
    content: <><p>Matthew was an absolute pleasure to work with! 
      He is a highly skilled individual, made great suggestions and 
      completed the work well before the timeline we had set.
      We are very much looking forward to working with Matthew again soon!</p></>,
    author: {
      name: 'Jesse',
      designation: 'Daytrader'
    }
  },
  {
    id: 5, smallText: false,
    content: <><p>
      This is our second project with Matthew and we are just as pleased with the results! 
      Matthew takes much pride in his work and has a thoroughness and thoughtfulness that is very much 
      appreciated by us!
    </p></>,
    author: {
      name: 'Jesse',
      designation: 'Daytrader'
    }
  },
  {
    id: 6, smallText: false,
    content: <><p>Matt did a great job with the quantconnect algo I needed. 
      On top of this, he's been extremely polite in answering all the questions and doubt I had. 
      I would definitely work with him again.</p></>,
    author: {
      name: 'Fabio',
      designation: 'Daytrader'
    }
  }
];

export const profitKitTestimonials = [
  {
    id: 0,
    content: 
      <><p>I've tried Baremetrics, ProfitWell, ChartMogul and various other subscription analytics tools but <b class='pk-blue-highlight'>none of them have come close to ProfitKit in terms of reliability and transparency.</b> In fact, all of them gave me completely different revenue numbers!</p><br /><p> When I mentioned this to Matt and Amy, they immediately wanted to help me get to the bottom of it and they eventually shipped a number of powerful customization features that fit my needs perfectly. <b class='pk-blue-highlight'>ProfitKit has easily become a must-have tool for running my SaaS.</b></p></>,
    author: {
      name: 'Jen Yip',
      designation: <>Founder of <a rel="noopener noreferrer" target="_blank" href="https://lunchmoney.app/">Lunch Money</a></>,
      image: "https://profitkitio-static-assets.s3.amazonaws.com/JenYipTestimonial.png",
    },
  },  
  {
    id: 1,
    content:
      <><p>This solved a HUGE problem we had. We are an agency with clients on a monthly billing cycle but had no way to forecast our upcoming revenue on a weekly basis.</p><br /><p>Our attempt at doing it manually using a spreadsheet was cumbersome and inaccurate. <b class='pk-blue-highlight'>ProfitKit saved us hours each week and ensured the accuracy of our weekly revenue projections</b>.</p></>,
    author: {
      name: "Kevin Wilke",
      designation: <>Co-Founder of <a rel="noopener noreferrer" target="_blank" href="https://www.provenmarketingsolutions.co/">Proven Marketing Solutions</a></>,
      image: "https://profitkitio-static-assets.s3.amazonaws.com/KevinWilkeTestimonial.png",
    }
  },
  {
    id: 2,
    content: 
      <><p>ProfitKit has been amazing for keeping customers engaged during trial to <b class='pk-blue-highlight'>increase our conversion rate</b>. The simplicity of it, while still leaving a lot of room for customization is key.</p><br /><p>Plus, Matt has worked tirelessly to make ProfitKit's MRR analytics <b class='pk-blue-highlight'>more accurate than any other tool we've tried. Even ProfitWell and Stripe itself!</b> This makes my job much easier when I need to report our revenue to our investors/team each week.</p></>,
    author: {
      name: "Ryan Born",
      designation: <>Co-Founder of <a rel="noopener noreferrer" target="_blank" href="https://cloudcampaign.io/">Cloud Campaign</a></>,
      image: "https://s3.amazonaws.com/profitkitio-static-assets/RyanBornTestimonial.jpg",
    }
  },
  {
    id: 3,
    content: 
      <><p>Only a few days after using ProfitKit, the automatic emails to unpaid subscribers reactivated 4 customers for $40 in MRR and collected $160 in past due payments. <b class='pk-blue-highlight'>I can't think of another piece of software I've used that has paid for itself so directly.</b></p></>,
    author: {
      name: "Chad Sakonchick",
      designation: <>Co-Founder of <a rel="noopener noreferrer" target="_blank" href="https://betterlegal.com/">BetterLegal</a></>,
      image: "https://s3.amazonaws.com/profitkitio-static-assets/ChadSakonchickTestimonial.jpg",
    }
  },
  {
    id: 4,
    content: 
      <><p>I was literally going to sit down and make a calendar like this myself tomorrow so this saved me so much time. So far <b class='pk-blue-highlight'>everything has been user friendly, especially the subscription calendar</b> -- that is a great tool.</p><br /><p>We run an online training business and have to send feedback forms to our clients a week before their next billing date, and <b class='pk-blue-highlight'>the email notifications have been a huge help for us in automating that process.</b></p></>,
    author: {
      name: "Alex Bryce",
      image: "https://s3.amazonaws.com/profitkitio-static-assets/AlexBryceTestimonial.jpg",
      designation: <>Co-Founder of <a rel="noopener noreferrer" target="_blank" href="https://electrumperformance.com/">Electrum Performance</a></>,
    }
  },
  {
    id: 5,
    content: 
      <><p>ProfitKit has turned into one of my top 5 daily apps. <b class='pk-blue-highlight'>It has become a vital part of Teach ’n Go’s forecasting model</b>. Getting on top of your forecast lets you accurately plan out your spend month on month, ensuring you have enough run rate to keep your business healthy and profitable.</p></>,
    author: {
      name: "Mark Jones",
      designation: <>Founder of <a rel="noopener noreferrer" target="_blank" href="https://www.teachngo.com/">Teach 'n Go</a></>,
    }
  },
  {
    id: 6,
    content: 
      <><p>Folks, check out ProfitKit, a must need tool for any SaaS business. At Remote Leaf, we faced a huge problem with user payments failing, and I've been manually reminding users to update their subscriptions. Then I found out about ProfitKit through Amy on Indiehackers. <b class='pk-blue-highlight'>Amy was super helpful in setting up the payment recovery integration and also wrote custom development code to fit my product’s specific needs.</b></p><br /><p>Highly recommend it!</p></>,
    author: {
      name: "Abinaya",
      designation: <>Founder of <a rel="noopener noreferrer" target="_blank" href="https://remoteleaf.com/">Remote Leaf</a></>,
    }
  }
];
