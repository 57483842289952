import React from "react";

function Sectiontitle(props) {
  return (
    <div className={`mi-sectiontitle${props.smallMargin ? ' mi-sectiontitle-small-margin' : ''}`} 
      style={props.style ? props.style : { }}>
      <h2>{props.title}</h2>
      <span>{props.title}</span>
    </div>
  );
}

export default Sectiontitle;
