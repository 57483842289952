import React from "react";
import {
  HashRouter,
  BrowserRouter,
  Redirect,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import TestimonialsPage from "./pages/TestimonialsPage";
import About from "./pages/About";
import Resumes from "./pages/Resumes";
import Portfolios from "./pages/Portfolios";
import SessionData from "./pages/SessionData";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import BlogDetails from "./pages/BlogDetails";
import Notfound from "./pages/Notfound";
import { LocationContextProvider } from "./context";

const RedirectComponent = () => {
  const location = useLocation();
  if (location && location.pathname) {
    return (
      <Redirect
        to={`${location.pathname.replace("portfolios", "portfolio")}`}
      />
    );
  }
  return <Home />;
};

function App() {
  return (
    <LocationContextProvider>
      <HashRouter>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/" exact component={Home} />
          <Route
            path="/schedule-a-call"
            exact
            component={() => <Home autoScheduleCall />}
          />
          <Route path="/session-data" exact component={SessionData} />
          {/* <Route path="/testimonials" exact component={TestimonialsPage} /> */}
          <Route path="/about" exact component={About} />
          <Route path="/resume" exact component={Resumes} />
          <Route path="/portfolio" exact component={Portfolios} />
          <Route
            path="/portfolio/portfolio-details/:id/:title"
            exact
            component={BlogDetails}
          />
          <Route path="/portfolios" component={RedirectComponent} />
          {/* <Route path="/blogs" exact component={Blogs} /> */}
          {/* <Route path="/blogs/blog-details/:id/:title" component={BlogDetails} /> */}
          <Route path="/contact" exact component={Contact} />
          <Route path="*" component={Notfound} />
        </Switch>
      </HashRouter>
    </LocationContextProvider>
  );
}

export default App;
