import React, { useState } from "react";
import * as Icon from "react-feather";
import FsLightbox from "fslightbox-react";

function Portfolio(props) {
  const [toggler, setToggler] = useState(false);
  const {title, subtitle, tags, imageUrl, largeImageUrl, url} = props.content;
  
  const handleToggler = (value) => {
    setToggler(value);
  }

  return (
    <div 
      className={"mi-portfolio mi-portfolio-visible"}
      // className={props.isVisible ? "mi-portfolio mi-portfolio-visible" : "mi-portfolio"}
      >
      <div className="mi-portfolio-image">
        <img src={imageUrl} alt={title} />
        <ul>
          {url && <li>
            <a rel="noopener noreferrer" target="_blank" href={url}>
              <Icon.Info/>
            </a>
          </li>}
          {largeImageUrl && <li>
            <button onClick={() => handleToggler(!toggler)}>
              <Icon.ZoomIn/>
            </button>
          </li>}
        </ul>
      </div>
      {!url ? <h5>{title}</h5> : <h5>
        <a rel="noopener noreferrer" target="_blank" href={url}>
          {title}
        </a>
      </h5>}
      <b className="description slight-bold">{tags || ""}</b>
      {subtitle ? <p>{subtitle}</p> : null}
      {!largeImageUrl ? null : <FsLightbox
        toggler={toggler}
        sources={largeImageUrl}
        />}
    </div>
  );
}

export default Portfolio;
