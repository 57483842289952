import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";
import { freelanceTestimonials, profitKitTestimonials } from './TestimonialsData';

function TestimonialsPage() {
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);

  const sliderSettingsTest = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    // ***
    // slidesToShow: 2,
    // slidesToScroll: 2,
    // ***
    // autoplay: true,
    // autoplaySpeed: 6000,
    // ***
    pauseOnHover: true,
    adaptiveHeight: true,
    draggable: false, // Added by Matt
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios.get("/api/services").then((response) => {
      setServices(response.data);
    });
    axios.get("/api/reviews").then((response) => {
      setReviews(response.data);
    });
  }, []);

  return (
    <Layout>   
      <div className="mi-review-area mi-section mi-padding-top page-padding">
        <div className="container">
          <Sectiontitle title="Freelancing" smallMargin />
          <p className="white-text large-description">
          From 2017 to the end of 2018, I worked as a freelance developer building 
          fullstack web applications and trading algorithms. My clients included a startup,
          online businesses, daytraders and various other individuals. 
          <br /><br />
          As a freelancer, I developed 4 web applications and 15 trading algorithms for clients.
          {/* on platforms such as QuantConnect and Metatrader. */}
          {/* I built fullstack web applications for various individuals and businesses, and 
          trading algorithms on platforms such as QuantConnect and Metatrader for daytraders. */}
          {/* I offered fullstack web & mobile development and  */}
          {/* <br />
          As a freelance developer, I worked on projects for a variety of clients. 
          Services I offered include fullstack web & mobile app development,
          trading algorithm development for daytraders and wordpress development */}
          </p>

          <div className="row justify-content-center">
            <div className="col-12 slider-container-padding">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {freelanceTestimonials.map(elem => 
                  <Testimonial 
                    key={elem.id} smallText={elem.smallText}
                    content={elem}                
                  />)}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="ProfitKit" smallMargin />
          <p className="white-text large-description">
          ProfitKit is a software product and business I co-founded in 2018 with my partner and fellow developer Amy. 
          As a product, ProfitKit provides revenue projections, failed payment recovery and automated customer emails 
          for subscription businesses that use Stripe. 
          <br /><br />
          We handled all aspects of business strategy, marketing, development and 
          customer engagement as a team of two with no external funding.
          On the engineering side, I was primarily the backend developer while Amy handled frontend development.
          </p>
          <div className="row justify-content-center">
            <div className="col-12 slider-container-padding">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {profitKitTestimonials.map(elem => 
                  <Testimonial 
                    key={elem.id} smallText={elem.smallText}
                    content={elem}                
                  />)}
                {/* {reviews.map((review) => (
                  <Testimonial key={review.id} content={review} />
                ))} */}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TestimonialsPage;
