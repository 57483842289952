import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

export const LocationContext = createContext({});

export const LocationContextProvider = ({ children }) => {
  const [geoLocationData, setGeoLocationData] = useState({});
  const fetchAndSetGeoLocationData = async () => {
    try {
      const geoLocationResponse = await axios.get(
        "https://geolocation-db.com/json/"
      );
      setGeoLocationData(geoLocationResponse.data);
    } catch (err) {}
  };
  useEffect(() => {
    fetchAndSetGeoLocationData();
  }, []);

  return (
    <LocationContext.Provider value={geoLocationData}>
      {children}
    </LocationContext.Provider>
  );
};

export const useSessionData = () => {
  const { IPv4, ...rest } = useContext(LocationContext);
  const trackAction = () => {
    console.log("");
  };
};
