import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";
import Service from "../components/Service";
import Testimonial from "../components/Testimonial";

function About() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleToggler = (event) => {
    setToggler({
      toggler: !toggler,
    });
  };

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    axios.get("/api/services").then((response) => {
      setServices(response.data);
    });
    axios.get("/api/reviews").then((response) => {
      setReviews(response.data);
    });
  }, []);

  const titleSpacing = {
    marginTop: 40,
    marginBottom: 40,
  };

  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          {/* <Sectiontitle title="About Me" /> */}
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-lg-4">
              <img
                src="/images/personal/Personal3.jpeg"
                alt="About Image"
                // onClick={() => handleToggler(!toggler)}
                height={400}
              />
              <div className="mi-about-image">
                <span className="mi-about-image-icon">
                  <Icon.ZoomIn />
                </span>
                <FsLightbox
                  toggler={toggler}
                  sources={[information.aboutImageLg]}
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="mi-about-content">
                {/* <h3>
                  I am <span className="color-theme">{information.name}</span>
                </h3> */}
                <h4 style={{ marginBottom: 20 }}>
                  {/* Hi! I'm Matt, a software developer with an interest in accounting and finance.  */}
                  {/* Hi! I'm Matt, a software developer with an interesting story about how I ended up here. */}
                  Hi! I'm Matt, a software developer who builds fullstack web
                  and mobile applications.
                </h4>
                <p className="bio-text">
                  When I'm not programming I enjoy writing, the outdoors
                  and&nbsp;Brazilian Jiu-Jitsu (pre-lockdown).
                </p>
                <p className="bio-text">
                  I've had an interesting 5 years as a software developer that
                  include a stint as a freelancer, a job at an architecture firm
                  and an entrepreneurial venture.
                </p>
                <p className="bio-text">
                  Keep reading to get the full story of how I became a developer
                  and where my career has taken me so far!
                  {/* what directions my career has taken over the last 5 years! */}
                  {/* and the twists and turns my career has taken over the last 5 years! */}
                </p>
                {false && (
                  <ul>
                    {!information.name ? null : (
                      <li>
                        <b>Full Name</b> {information.name}
                      </li>
                    )}
                    {!information.age ? null : (
                      <li>
                        <b>Age</b> {information.age} Years
                      </li>
                    )}
                    {!information.phone ? null : (
                      <li>
                        <b>Phone</b> {information.phone}
                      </li>
                    )}
                    {!information.nationality ? null : (
                      <li>
                        <b>Nationality</b> {information.nationality}
                      </li>
                    )}
                    {!information.language ? null : (
                      <li>
                        <b>Languages</b> {information.language}
                      </li>
                    )}
                    {!information.email ? null : (
                      <li>
                        <b>Email</b> {information.email}
                      </li>
                    )}
                    {!information.address ? null : (
                      <li>
                        <b>Address</b> {information.address}
                      </li>
                    )}
                    {!information.freelanceStatus ? null : (
                      <li>
                        <b>Freelance</b> {information.freelanceStatus}
                      </li>
                    )}
                  </ul>
                )}
                {/* <a href={information.cvfile} className="mi-button">
                  Download CV
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="mi-section" style={{ paddingTop: 44 }}>
        <div className="container">
          <Sectiontitle title="The Beginning" />
          <div className="row">
            <div className="col-lg-12">
              <div className="mi-about-content bio-text">
                {/* <h4 style={{ marginBottom: 20 }}>
              Hi! I'm Matt, a software developer with an interest in accounting and finance. 
              </h4> */}
                {/* <br /> */}
                <p>
                  It all started while I was an undergraduate at the University
                  of Chicago at the beginning of 2017.
                </p>
                <p>
                  Having thoroughly fumbled my course selection for my major and
                  with no real employable skills to speak of, I decided to learn
                  programming online through Udemy courses.
                </p>
                <p>
                  Within time I realized that having actual projects to work on
                  was the best way to learn, and quickly found these projects by
                  advertising myself as a freelance developer.
                </p>
                <Sectiontitle title="Freelancing" style={titleSpacing} />
                <p>
                  There was a lot of learning on the job in those days, and the
                  first two freelance projects I worked on taught me how to
                  build rough but functional web apps using a combination of
                  Django and Bootstrap.
                </p>
                <p>
                  With some prior experience I'd gained studying the stock
                  market, I also built trading algorithms using Python and C#
                  for a subset of my clients who were daytraders. The daytraders
                  would use my algorithms to backtest their strategies and
                  validate their theories on the market.
                </p>
                <p>
                  This ended up being a rather popular service and provided the
                  majority of my income during this time.
                </p>
                <p>
                  After a year of hacking it as a self-taught and freelance
                  developer, I decided it was time to learn the <i>proper</i>{" "}
                  way of programming.
                </p>
                <Sectiontitle title="Fullstack Academy" style={titleSpacing} />
                <p>
                  I attended Fullstack Academy's&nbsp;
                  <b>
                    <a
                      href="https://www.fullstackacademy.com/software-engineering-immersive"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      3-month bootcamp
                    </a>
                  </b>
                  &nbsp;where I learned industry-standard coding practices and
                  built several full stack web applications using Node.js,
                  Express, Sequelize, React and Redux.
                </p>
                <p>
                  The bootcamp showed me what code for a fullstack web
                  application is supposed to look like, and I ended up rewriting
                  one of my former client's web applications in Node.js.
                </p>
                <p>
                  I was excited to see a significant improvement in the quality
                  of the site. It ran faster, there were less bugs and the code
                  was far more organized than it was before!
                </p>
                <Sectiontitle title="Draftbit" style={titleSpacing} />
                <p>
                  From there, I continued with freelance work for a few months
                  before landing a 6-week contract as a fulltime mobile
                  developer for Draftbit.
                </p>
                <p>
                  At Draftbit, I found myself thrown into the proverbial deep
                  end again when I learned React Native on the job and used it
                  to create an MVP mobile application for one of their clients.
                </p>
                <Sectiontitle title="Perkins & Will" style={titleSpacing} />
                <p>
                  After my contract with Draftbit ended, I accepted a job with
                  the architecture firm Perkins & Will at their Chicago office.
                </p>
                <p>
                  As a 5-year resident of Chicago at the time, I was excited to
                  work in the iconic Wrigley building and enjoy a nice view of
                  the city during lunch:
                </p>
                <div className="col-lg-12 horizontal-container flex-wrap">
                  <img
                    src={"/images/wrigley-building/WrigleyFromStreet.jpg"}
                    alt="Wrigley Building"
                    className="col-lg-6"
                  />
                  <img
                    src={"/images/wrigley-building/WrigleyLunchView2.jpg"}
                    className="col-lg-6"
                    alt="Wrigley Building View"
                  />
                </div>
                <br />
                <p>
                  At Perkins & Will, I was part of a small team responsible for
                  the development of experimental digital ideas.
                </p>
                <p>
                  Due to a limited number of engineers, I was often the sole or
                  lead developer for projects we would undertake. This made me
                  well-versed in both frontend and backend development, as well
                  as deploying and debugging applications on AWS.
                </p>
                <p>
                  I was also exposed to a variety of spatial and data
                  visualization tools such as Mapbox, D3, Revit and Autodesk
                  Forge. Learning new APIs to use and interact with these tools
                  was an interesting aspect of my role.
                </p>
                <p>
                  My previous experience building trading algorithms with C#
                  came in handy on a trip to New York where we showcased a{" "}
                  <a
                    href="/#/portfolio/portfolio-details/3/massformer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <b>3D-modelling web application called Massformer</b>
                  </a>
                  .
                </p>
                <p>
                  Using C#, I wrote a plugin to generate Revit files based on
                  building geometry created from the Massformer application:
                </p>
                <img
                  src="/images/MassformerRevitConversion.png"
                  alt="Massformer Revit File"
                  // onClick={() => handleToggler(!toggler)}
                />
                <br />
                <br />
                {/* <div className="col-lg-12">
              </div> */}
                <p>
                  To learn more about the work I was involved in at Perkins &
                  Will, you can see a showcase of our team's projects at:
                  <br />
                  {/* &nbsp; */}
                  <a
                    href="http://insight.perkinswill.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://insight.perkinswill.io/
                  </a>
                </p>
                <Sectiontitle title="ProfitKit" style={titleSpacing} />
                <p>
                  During my time as a freelancer, I gained experience working
                  with the Stripe API and using it to add subscription-based
                  billing for online businesses. I loved how easy and seamless
                  the integration was and the level of customization and
                  analytics their API allowed.
                </p>
                <p>
                  In 2018 I decided to try my hand at creating a software
                  product that would provide revenue projections to small
                  businesses running on Stripe, and co-founded ProfitKit.
                </p>
                <p>
                  What started as a small project eventually took up more and
                  more of our time as users signed up, provided feedback and
                  started asking for additional features. Before we knew it,{" "}
                  <b>
                    <a
                      href="https://www.profitkit.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ProfitKit
                    </a>
                  </b>{" "}
                  had become a significant time investment on top of our
                  fulltime jobs.
                </p>
                <img
                  src="/images/ProfitKitLanding.png"
                  alt="ProfitKit Screenshot"
                />
                <br />
                <br />
                <p>
                  At the end of 2019, I asked to transition my fulltime role
                  with Perkins & Will into a remote contract.
                </p>
                <p>
                  This allowed me the time and flexibility in my schedule to
                  handle the growing workload from ProfitKit, which included
                  marketing, customer outreach, user engagement and of course,
                  the engineering that went into launching new features and
                  ensuring the product ran smoothly.
                </p>
                <p>
                  We experienced significant growth in the first few months of
                  2020 and were excited to see businesses from different
                  industries convert into paying users. Our customers ranged
                  from small, homegrown businesses like ourselves to large
                  companies generating millions of dollars in annual revenue.
                </p>
                <p>
                  Towards the latter half of the year however, we felt our
                  product stagnate as COVID made businesses more cost-adverse
                  and we found ourselves pulled in an increasing number of
                  different directions when it came to feature requests.
                </p>
                <p>
                  Ultimately we decided that while we had experienced some
                  success, we weren't growing fast enough to justify our
                  continued fulltime commitment to ProfitKit.
                </p>
                {/* <Sectiontitle title="What Now?" style={titleSpacing} />
                <p>That brings us to today.</p>
                <p>
                  I've since had some enriching experiences as an engineer, from
                  standing in as a technical lead for the Credit Union National
                  Association to architecting
                </p>
                <p>
                  While I'm still working as a contractor for Perkins & Will,
                  lately I've been feeling the need to find a role that provides
                  more opportunities for technical growth.
                </p>
                <p>
                  In particular, I want to get better at using cloud computing
                  platforms and scaling up web applications to support large
                  volumes of data and users - two challenges we faced often with
                  ProfitKit.
                </p>
                <p>
                  As such, I'm looking for a new fulltime development role that
                  ideally provides a level of technical mentorship and
                  opportunities to work on or create fullstack applications.
                </p>
                <p>
                  Looking back on my career as a developer, a constant trend for
                  me has been learning new technologies, often on the job -
                  whether it was a programming language like C#, a development
                  framework like React Native or an API such as Mapbox or
                  Autodesk Forge.
                </p>
                <p>
                  Adaptability and the skill of learning on the job has always
                  been one of my strongest traits, and I look forward to
                  bringing that to wherever I work next.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      {/* <div className="mi-service-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Services" />
          <div className="mi-service-wrapper">
            <div className="row mt-30-reverse">
              {services.map((service) => (
                <div
                  className="col-lg-4 col-md-6 col-12 mt-30"
                  key={service.title}
                >
                  <Service content={service} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
        {/* <div className="container">
          <Sectiontitle title="Reviews" />
          <div className="row justify-content-center">
            <div className="col-12">
              <Slider className="mi-testimonial-slider" {...sliderSettings}>
                {reviews.map((review) => (
                  <Testimonial key={review.id} content={review} />
                ))}
              </Slider>
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  );
}

export default About;
