import React from "react";

export let timelineData = [
  {
    id: 5,
    year: 2017,
    position:
      "Learned programming online while in college",
    company: <>University of Chicago</>,
    details:
      <>
        Developing an interest in programming but too late into my undergraduate education to switch degrees, I started learning how to code and build web applications using online courses from sites like Udemy.
      </>
  },
  {
    id: 4,
    year: "2017 - 2018",
    position: "Started freelancing as a developer",
    company: <>Online businesses and daytraders</>,
    details:
      <><p>Eager to put my newfound skills to the test and gain work experience,
        I started freelancing as a developer for clients I found both online and in my personal network.
        Much of my learning at this time was done on the job.</p><br />
        <p>This is something I continued until my full-time employment at Perkins + Will in November of 2018.</p></>
  },
  {
    id: 3,
    year: "2018",
    position: "Fullstack Academy",
    details: <>Desiring a more structured development education, I enrolled in Fullstack Academy's coding bootcamp where I learned to
      build web applications with a proper methodology and modern stack.</>
  },
  // Draftbit (2018 October - 2018 November)
  {
    id: 2,
    year: "2018",
    position: "Mobile Developer",
    company: <>Draftbit</>,
    details: <>
      <p>My first full-time position as a software developer was a 6-week contract with the mobile development startup Draftbit.
        I developed a variety of different mobile app screens and created an functional MVP of a mobile application for one of
        Draftbit's clients using React Native and the Airtable API as a database.</p>
      <br />
      <p>This was my first time working with the React Native framework for mobile app development, a skill I would later use to
        build an app for tracking rest times while weightlifting.</p>
      <br />
      <b>Technology & skills used:</b> React Native, Airtable, Airtable API
    </>
  },
  {
    id: 1,
    year: <>
      2018 - Present
      <br /><br />
      2020 - Present (Remote)
    </>,
    position: "Fullstack Web Developer",
    company: "Perkins & Will",
    details: <>
      Following my Draftbit contract, I started a full-time position with architecture firm Perkins + Will as a fullstack developer.
      <br /><br />
      I was part of a small team working to digitize the company's existing workflows and develop new architectural software that could serve as in-house tools or productized for architects outside of Perkins + Will.
      <br /><br />
      This role saw the development of numerous web applications over the span of a year, some of which remained prototypes and others which were used by the company as in-house tools.
      <br /><br />
      As the team's first junior developer, I was often the sole or lead developer of projects we undertook with some guidance from the senior engineer at the time (who was largely busy with maintaining the company's existing project management software). Although this presented its own challenges it also provided me a high degree of ownership when it came to my work, which I enjoyed.
      <br /><br />
      After a year of working full-time with Perkins + Will, I elected to transition to a part-time contract position at the end of 2019 in order to pursue an entrepreneurial venture - ProfitKit.</>
  },
  {
    id: 0,
    year: "2018 - 2020",
    position: "Co-Founder & Developer",
    company: "ProfitKit",
    details: <>
      In 2018, I co-founded a software product that provides revenue projections to subscription businesses running on Stripe.
      This decision was based on previous experience working on freelance projects together, during which we were impressed at the versatility
      of Stripe's API.
      {/* What started as a small project eventually took up more and more of our time as users signed up, provided feedback and started asking for additional features. 
      Before we knew it, ProfitKit had become a significant time investment on top of our fulltime jobs. */}
      <br /><br />
      Over the course of two years we built ProfitKit, a software as a service that projects future revenue, automates customer outreach
      and recovers failed subscription payments for subscription businesses that use Stripe.
      <br /><br />
      During this time, we handled all aspects of marketing, development and customer engagement as a team of two without external funding.
      <br /><br />
      We pushed our existing technical skills to the limit and learned new ones to help us save and load large amounts of data
      in an optimized way and deploy our product seamlessly on AWS.
      <br /><br />
      Working on ProfitKit was a challenging but rewarding experience for us, and we were encouraged by sudden growth at the beginning of 2020 and largely positive feedback
      from our paying users.
      <br /><br />
      Our customers ranged from small, homegrown businesses like ourselves to large companies generating millions in annual revenue.
      <br /><br />
      Towards the latter half of 2020 however, we felt our product stagnate as COVID made businesses more cost-adverse and found ourselves pulled in an increasing number of
      different directions when it came to feature requests.
      <br /><br />
      Ultimately we decided that while we had experienced some success, ProfitKit wasn't growing fast enough to justify
      our continued fulltime commitment.
    </>
  }
];