import React, { useState, useEffect, useContext } from "react";
import { useLocation, Redirect } from "react-router-dom";
import axios from "axios";
import Particles from "react-particles-js";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";
import { openCalendly } from "../utils";
import { LocationContext } from "../context";

function Home({ autoScheduleCall = false }) {
  const locationContext = useContext(LocationContext);
  const location = useLocation();
  const [information, setInformation] = useState("");
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#ffffff",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };

  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    if (autoScheduleCall) {
      openCalendly();
    }
  }, [autoScheduleCall]);
  if (location && location.hash) {
    if (location.hash.startsWith("#/portfolios")) {
      return (
        <Redirect
          to={`${location.hash.replace("#/portfolios", "/portfolio")}`}
        />
      );
    } else if (location.hash.startsWith("#")) {
      return <Redirect to={`${location.hash.replace("#", "")}`} />;
    }
  }
  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles className="mi-home-particle" params={paramConfig} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Hi, I'm{" "}
                  <span className="color-theme">{information.name}</span> and...
                </h1>
                <p>
                  I've worked as a fullstack developer for 7 years with an
                  emphasis on <br />
                  technical leadership, adaptability, and driving projects to
                  completion.
                </p>
                <p>
                  Throughout my career, I've been involved in 18 web and 2
                  mobile applications,
                  <br />
                  including 16 professional projects and a{" "}
                  <a
                    href="/#/portfolio/portfolio-details/7/profitkit"
                    target="_blank"
                  >
                    SaaS product I co-founded
                  </a>
                  .
                </p>
                {/* <p>
                  I'm experienced in developing end-to-end web applications,
                  REST APIs and microservices using TypeScript, React, Node, and
                  SQL. I have broad skills across payment services,
                  architecture, project management and SaaS.
                </p> */}
                <p>
                  I value developer autonomy but also enjoy collaborating and
                  exchanging knowledge within a team. Click around to learn more
                  about my experiences as a developer!
                </p>
                <br />
                <a href={information.cvfile} className="mi-button">
                  Download Resume
                </a>
                <br /> <br />
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    openCalendly();
                  }}
                  href="#"
                  className="mi-button inverted"
                >
                  Schedule a Call
                </a>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    textAlign: "left",
                  }}
                >
                  {/* <li>Throughout my career I've been involved in the development of 12 professional fullstack projects, 9 in which I was the sole or 
                  lead developer</li>
                  <li>My primary development stack consists of Node.js, Express, Sequelize & React</li> */}
                  {/* <li>Test 3</li> */}
                </ul>
                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
