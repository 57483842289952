import Mock from "../mock";

const database = {
  information: {
    name: "Matt",
    aboutContent:
      "I am a frontend web developer. I can provide clean code and pixel perfect design. I also make website more & more interactive with web animations.",
    age: 24,
    phone: "",
    nationality: "American",
    language: "English, French",
    email: "",
    address: "121 King Street, Melbourne, Australia",
    freelanceStatus: "Available",
    socialLinks: {
      medium: "https://medium.com/@matt-chan",
      // facebook: "https://facebook.com",
      // twitter: "https://twitter.com",
      pinterest: "",
      behance: "",
      linkedin: "https://www.linkedin.com/in/matthew-chan-developer/",
      dribbble: "",
      github: "https://github.com/mc2147",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/MatthewChanResume.pdf",
  },
  services: [
    {
      title: "Web Design",
      icon: "color-pallet",
      details:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    },
    {
      title: "Web Development",
      icon: "code",
      details:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    },
    {
      title: "Mobile Application",
      icon: "mobile",
      details:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "HTML5",
      value: 95,
    },
    {
      title: "CSS3",
      value: 90,
    },
    {
      title: "Javascript",
      value: 70,
    },
    {
      title: "jQuery",
      value: 85,
    },
    {
      title: "ReactJS",
      value: 80,
    },
    {
      title: "Photoshop",
      value: 65,
    },
  ],
  portfolios: [
    {
      id: 7,
      title: "ProfitKit",
      tags: "SaaS | Co-Founder",
      subtitle: "Revenue projections for Stripe subscription businesses",
      imageUrl: "/images/ProfitKitLanding.png",
      largeImageUrl: ["/images/ProfitKitLanding.png"],
      url: `/#/portfolio/portfolio-details/7/profitkit`,
    },
    {
      id: 6,
      title: "Precision Lifts",
      tags: "Personal Project | Solo Developer",
      subtitle: "Mobile app for workouts",
      imageUrl: "/images/PrecisionLiftsMain.png",
      largeImageUrl: ["/images/PrecisionLiftsMain.png"],
      url: `/#/portfolio/portfolio-details/6/precisionlifts`,
    },
    {
      id: 3,
      title: "Massformer",
      tags: "Perkins & Will | Solo & Lead Developer",
      subtitle: "3D architectural planning application",
      imageUrl: "/images/MassformerDemo3.png",
      largeImageUrl: ["/images/MassformerDemo3.png"],
      url: `/#/portfolio/portfolio-details/3/massformer`,
    },
    {
      id: 5,
      title: "Cube IO",
      tags: "Perkins & Will | Frontend Developer",
      subtitle: "Meeting engagement application with hardware components",
      imageUrl: "/images/CubeIOTitle.png",
      // "/images/CubeIO3.svg",
      largeImageUrl: ["/images/CubeIOTitle.png"],
      // "/images/CubeIO3.svg"],
      url: `/#/portfolio/portfolio-details/5/cubeio`,
    },
    {
      id: 4,
      title: "HubConnect",
      tags: "Perkins & Will | Solo Developer",
      subtitle: "Email relationships data visualizer",
      imageUrl: "/images/HubConnect3.png",
      largeImageUrl: ["/images/HubConnect3.png"],
      url: `/#/portfolio/portfolio-details/4/hubconnect`,
    },
    {
      id: 1,
      title: "Electrum Performance",
      tags: "Freelance Project | Backend Developer",
      subtitle: "Online personal training platform",
      imageUrl: "/images/EP-Collage.jpg",
      largeImageUrl: ["/images/EP-Collage.jpg"],
      url: `/#/portfolio/portfolio-details/1/electrum-performance`,
    },
    {
      id: 2,
      title: "Packfinder",
      tags: "Educational Project | 1 of 4 Developers",
      subtitle: "Social networking application for dog owners",
      imageUrl: "/images/PackfinderCollage.jpg",
      largeImageUrl: [
        "/images/PackfinderCollage-Half.jpg",
        "/images/PackfinderCollage-Half-2.jpg",
      ],
      url: `/#/portfolio/portfolio-details/2/packfinder`,
    },
    {
      id: 0,
      title: "Jon Dollars",
      tags: "Freelance Project | Solo Developer",
      subtitle: "Classroom rewards app",
      imageUrl: "/images/JDCollage.jpg",
      largeImageUrl: ["/images/JDCollage.jpg"],
      url: `/#/portfolio/portfolio-details/0/jon-dollars`,
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "Started freelancing as programmer",
        company: "Abc Company",
        // details: <><p>Eager to put my newfound skills to the test and gain work experience,
        //   I started freelancing as a developer for whatever clients I could find both online and in my personal network.
        //   Much of my learning at this time was done "on the job".</p></>
      },
      {
        id: 2,
        year: "2016 - 2018",
        position: "Frontend Web Developer",
        company: "CBA Company",
        details:
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      },
      {
        id: 3,
        year: "2014 - 1016",
        position: "UI/UX Designer",
        company: "Example Company",
        details:
          "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro.",
      },
    ],
    educationExperience: [
      // {
      //   id: 1,
      //   year: "2018 - 2019",
      //   graduation: "Master of Science",
      //   university: "Abc University",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // },
      // {
      //   id: 2,
      //   year: "2016 - 2018",
      //   graduation: "Bachelor of Science",
      //   university: "Abc University",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // },
      // {
      //   id: 3,
      //   year: "2015 - 2016",
      //   graduation: "Higher Schoold Graduation",
      //   university: "Abc College",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // }
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["+012-3456-7891", "+012-3456-7892"],
    emailAddress: ["admin.sitename@example.com", "info.sitename@example.com"],
    address: "121 King Street, Melbourne, Victoria 3000, Australia",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  if (config && config.params) {
    const searchTags = config.params.tags;
    if (searchTags) {
      if (Array.isArray(searchTags)) {
        const response = database.portfolios.filter(({ tags }) =>
          searchTags.some((searchTagValue) => tags.includes(searchTagValue))
        );
        return [200, response];
      } else if (typeof searchTags === "string") {
        const response = database.portfolios.filter(({ tags }) =>
          tags.includes(searchTags)
        );
        return [200, response];
      }
    }
  }
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
